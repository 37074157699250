<template>
	<div class="mainTem">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini"
							@click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button>
						<span class="tct_tit">渠道费用类型新增</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <el-button type="success" @click="openDioalog(null, $t('c0246c55b9cac963'))" size="small"
						icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>
						
					<el-button @click="delAction($event, null)" type="danger" size="small"
						icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button> -->

					<el-button type="success" @click="submitAction()" size="small" icon="el-icon-document">全部保存</el-button>

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<!-- <li>
					<span>{{ $t('hytxs0000060') }}</span>
					<cusSelFuzzy ref="cusSelFuzzy" :size="'small'" @changeData="changCus"></cusSelFuzzy>
				</li>
				<li>
					<span>{{ $t('c944a6686d996ab3') }}</span>
					<whNoSelect ref="whNoSelect" :size="'small'" @changeData="changWhNo"></whNoSelect>
				</li> -->
				<li>
					<span>lgsKeyCode</span>
					<el-select filterable clearable size="small" v-model="filterData.lgsKeyCode"
						:placeholder="$t('2ad108ab2c560530')" style="width: 200px;" @change="lgsCodeChange">
						<el-option v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="index"
							:label="item.logistics+'('+item.code+')'+'('+item.codeName+')'" :value="item.code">
							<div style="display: flex; justify-content: space-between;">
								<div>{{ item.logistics }}({{ item.code }})({{ item.codeName }})</div>
								<div style="color: #8492a6; font-size: 13px">
									<span v-if="0 == item.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
									<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
								</div>
							</div>
						</el-option>
					</el-select>
				</li>
				<!-- <li>
					<span>channel</span>
					<el-select filterable clearable v-model="filterData.channel" :placeholder="$t('i18nn_2ad108ab2c560530')"
						style="width: 200px;">
						<el-option v-for="(item,index) in selectOption.channelList" :key="index" :label="item.name"
							:value="item.key">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ item.name }}</span>
								<span class="sel_option_code" style="">{{ item.key }}</span>
							</div>
						</el-option>
					</el-select>
				</li> -->
				<!-- <li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input type="text" v-model="filterData.keyword" size="small" clearable @keyup.enter.native="initData()"
						maxlength="50" :placeholder="$t('custom_enterfuzzysearch')" style="width: 220px;" />
				</li> -->
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('1e7246dd6ccc5539') }}
					</el-button>
				</li>
			</ul>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">

			<el-tabs type="border-card">
				<el-tab-pane v-for="(item,index) in tableData" :key="index">
					<span slot="label">
						{{item.feeCode}}
					</span>
					<div>
						<!-- <el-button type="success" @click="submitOneAction(index)" size="small" icon="el-icon-document">保存</el-button> -->
						<CarrFeeTypePriceHotTable :ref="'HotTable'+index" :selectDicOption="{}">
						</CarrFeeTypePriceHotTable>
					</div>
				</el-tab-pane>
			</el-tabs>



			<!-- <div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div> -->
		</div>

		<!--新增-->
		<!-- <RemoteAreaCodingConfigAdd :openTime="whAddOpenTime" :row="whRow" @success="AddSuccessBack">
		</RemoteAreaCodingConfigAdd> -->

	</div>
</template>
<script>
	// import RemoteAreaCodingConfigAdd from '@/components/WarehouseCenter2/RemoteAreaCodingConfig/RemoteAreaCodingConfigAdd.vue';

	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	import CarrFeeTypePriceHotTable from './hotTable/CarrFeeTypePriceHotTable.vue';
	export default {
		components: {
			CarrFeeTypePriceHotTable
			// cusSelFuzzy,
			// whNoSelect,
			// RemoteAreaCodingConfigAdd
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false
		// 		},
		// 		type: Boolean
		// 	},
		// },
		data() {
			return {
				// whAddOpenTime: '',
				// whRow: {},

				loading: false,

				// multipleSelection: [],

				loading_load: false,
				tableData: [],

				//分页数据
				// pagination: this.$Utils.defaultPagination(),

				selectOption: {
					biz_wh_lgskey_code: [],
					// channelList:[],
				},
				//查询，排序方式
				filterData: {
					// orderBy: 'sort_no', //排序字段
					// sortAsc: 'N', //desc降序，asc升序
					// userName: '',
					lgsKeyCode: "",
					channel: "",

					// userId: '',
					// whNo: '',
					// keyword: ''
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.initData();
			this.getLGSByCodeData();
		},
		methods: {
			initData() {
				// this.multipleSelection = [];
				this.tableData = [];
				// this.clearAllHotTable(30);


				// this.pagination.current_page = 1;
				this.getPageData();

				// this.$nextTick(() => {
				// 	this.$refs.multipleTable.doLayout();
				// });
			},
			clearAllHotTable(length) {

				this.$nextTick(() => {
					for (let i = 0, ln = length; i < ln; i++) {
						if (this.$refs['HotTable' + i] && this.$refs['HotTable' + i][0]) {
							// this.$refs.HotTable.initHotTable();
							this.$refs['HotTable' + i][0].clear();
						}
					}
				});


			},
			goBack() {
				this.$router.go(-1);
			},

			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	this.initData();
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},


			//lgsCode改变
			lgsCodeChange(key) {



				this.filterData.channel = "";
				// this.form.signature = "";
				let selLgsData = this.selectOption.biz_wh_lgskey_code.find(item => {
					return key == item.code;
				});

				this.filterData.channel = selLgsData.logistics;

				this.getPageData()

				//旧-渠道代码-数据字典
				// this.getChanneByCodeData(key);

				//渠道代码
				// if (!!selLgsData && !!selLgsData.extendParamJson && !!selLgsData.extendParamJson.channels) {
				// 	this.selectOption.channelList =selLgsData.extendParamJson.channels;
				// } else {
				// 	this.selectOption.channelList = [];
				// }
				//签名
				// if (!!this.selLgsData && !!this.selLgsData.extendParamJson && !!this.selLgsData.extendParamJson.signatures) {
				// 	this.selectOption.wh_signature_service = this.selLgsData.extendParamJson.signatures;
				// } else {
				// 	this.selectOption.wh_signature_service = [];
				// }

				//cope_fedex 原-> biz_wh_lgskey_cope_fedex 发货地址不可编辑-
				// cope_ups   cope_usps
				// if ('cope_fedex' === key || 'cope_ups' === key || 'cope_usps' === key) {
				//限制发货地址
				// if(this.onlySenderLgsCode.includes(key)){
				// 	this.getCopeShipFrom(key);
				// }
				// 仅查询
				// this.allChannelList = [];
				// if('3'==this.dialogFormVisibleType && this.selLgsData && this.selLgsData.extendParamJson){
				// 	this.allChannel = true;
				// 	this.allChannelList = this.selLgsData.extendParamJson.channels;
				// }
			},

			//lgscode
			getLGSByCodeData() {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
						isUse: '1'
					})
					.then(({
						data
					}) => {
						console.log('查询lgs，请求成功');
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							this.selectOption.biz_wh_lgskey_code = data.rows;
						} else {
							if (!data.msg) {
								data.msg = 'lgs' + this.$t('i18nn_323ee425eca7208c');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						this.loading = false;
						console.log('查询数据字典接口，请求失败');
						this.$message.error('lgs' + this.$t('i18nn_323ee425eca7208c') + '！');
					});
			},

			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	this.whRow = formParm;
			// 	this.whAddOpenTime = new Date().getTime();

			// },
			// //打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// },
			// AddSuccessBack() {
			// 	this.initData();
			// },

			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhRemoteAreaCodeConfigDel, {}, 'delete');
			// 		})
			// 		.catch(() => {});
			// },

			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {});
			// },
			// //删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);
			// 	let parm = [];

			// 	if (!!row) {
			// 		//单条
			// 		parm = [row.id];
			// 	} else {
			// 		//多条
			// 		let dataList = this.multipleSelection;
			// 		if (dataList.length < 1) {
			// 			this.$message.warning(this.$t('7b80e66b535a3732'));
			// 			return;
			// 		}
			// 		let dataListParm = dataList.map(v => v.id);
			// 		parm = dataListParm;
			// 		// console.log('dataListParm', dataListParm);
			// 		// let dataParm = {
			// 		// 	ids: dataListParm
			// 		// };
			// 	}

			// 	this.postData(this.$urlConfig.WhRemoteAreaCodeConfigDel, {
			// 		ids: parm
			// 	}, '', () => {
			// 		this.$message({
			// 			type: 'success',
			// 			message: this.$t('tips.successfullyDelete')
			// 		});
			// 		this.getPageData();
			// 	});
			// },

			//开启
			// openAction(event, row) {
			// 	this.postData(this.$urlConfig.WhThirdWmsConfigOpenClose, {
			// 		id: row.id
			// 	}, '');
			// },
			// //关闭
			// closeAction(event, row) {
			// 	this.postData(this.$urlConfig.WhThirdWmsConfigOpenClose, {
			// 		id: row.id
			// 	}, '');
			// },
			//
			getAllData() {
				let data = this.tableData;
				let reArr = [];
				// this.$nextTick(() => {
				for (let i = 0, ln = data.length; i < ln; i++) {
					// console.log('HotTable' + i, this.$refs['HotTable' + i])
					// console.log('HotTable[0]' + i, this.$refs['HotTable' + i][0])
					// this.$nextTick(() => {
					if (this.$refs['HotTable' + i] && this.$refs['HotTable' + i][0]) {
						// let arr_old = data[i].feeTypeArr;
						// let arr_new = [];
						// for (let j = 0, lnj = arr_old.length; j < lnj; j++) {
						// 	let obj_old = arr_old[j];
						// 	let obj = {
						// 		lgsCode: obj_old.feePriceInfo.lgsCode,
						// 		Zones: obj_old.feePriceInfo.Zones,
						// 		zone2Price: obj_old.feePriceInfo.zone2Price,
						// 		zone3Price: obj_old.feePriceInfo.zone3Price,
						// 		zone4Price: obj_old.feePriceInfo.zone4Price,
						// 		zone5Price: obj_old.feePriceInfo.zone5Price,
						// 		zone6Price: obj_old.feePriceInfo.zone6Price,
						// 		zone7Price: obj_old.feePriceInfo.zone7Price,
						// 		zone8Price: obj_old.feePriceInfo.zone8Price,
						// 		zone9Price: obj_old.feePriceInfo.zone9Price,
						// 		zone10Price: obj_old.feePriceInfo.zone10Price,
						// 		zone11Price: obj_old.feePriceInfo.zone11Price,
						// 		zone12Price: obj_old.feePriceInfo.zone12Price,
						// 		feeType: obj_old.feeTypeInfo.feeType,
						// 		isSupport: obj_old.feePriceInfo.zone2Price,
						// 		isAddSf: obj_old.feePriceInfo.zone2Price,
						// 	}
						// 	arr_new.push(obj);
						// }
						// this.$refs['HotTable' + i][0].loadData(arr_new);
						// this.$nextTick(() => {
							// console.log('this.$refs[HotTable aaaa][0].getData()',this.$refs['HotTable' + i][0].getData());
						// reArr.concat(this.$refs['HotTable' + i][0].getData())
						reArr = reArr.concat(this.$refs['HotTable' + i][0].getData());
						// reArr.push(this.$refs['HotTable' + i][0].getData())
						// })
					}
					// });
				}
				// });
				return reArr;
			},
			
			//单个保存
			// submitOneData(index){
			// 	this.$nextTick(() => {
			// 		// {"lgsCode":"","carrier":"","carrierFeePriceList":[{"feeType":"","zone2Price":"","zone3Price":"","zone4Price":"","zone5Price":"","zone6Price":"","zone7Price":"","zone8Price":"","isSupport":"是否支持","isAddSf":"是否加服务费"}]}
			// 		let formData = {};
			// 		formData.lgsCode = this.filterData.lgsKeyCode;
			// 		formData.carrier = this.filterData.channel;
				
			// 		formData.carrierFeePriceList = this.$refs['HotTable' + index][0].getData();
				
			// 		this.postData(this.$urlConfig.WhCarrFeePriceConfigAdd, formData, '', () => {
			// 			this.$message.success("保存成功");
						
			// 			this.getPageData();
			// 			// this.goBack();
				
			// 		});
			// 	})
			// },
			// submitOneAction(index){
			// 	this.$confirm('是否确定提交？', this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.submitOneData(index);
			// 		})
			// 		.catch(() => {});
			// },

			//全部保存
			submitData() {
				this.$nextTick(() => {
					// {"lgsCode":"","carrier":"","carrierFeePriceList":[{"feeType":"","zone2Price":"","zone3Price":"","zone4Price":"","zone5Price":"","zone6Price":"","zone7Price":"","zone8Price":"","isSupport":"是否支持","isAddSf":"是否加服务费"}]}
					let formData = {};
					formData.lgsCode = this.filterData.lgsKeyCode;
					formData.carrier = this.filterData.channel;

					formData.carrierFeePriceList = this.getAllData();

					this.postData(this.$urlConfig.WhCarrFeePriceConfigAdd, formData, '', () => {
						this.$message.success("保存成功");

						this.goBack();

					});
				})
			},
			
			

			//保存
			submitAction() {
				this.$confirm('是否确定提交？', this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.submitData();
					})
					.catch(() => {});
			},

			//提交信息
			postData(url, formData, type, callback) {
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.getPageData();
						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						// 	this.$message.success(this.$t('tips.submitSuccess'));
						// }
						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

			//查询数据
			// serPageData() {
			// 	// this.pagination.current_page = 1;
			// 	this.getPageData();
			// },

			loadAllHotTableData(data) {

				this.$nextTick(() => {
					for (let i = 0, ln = data.length; i < ln; i++) {
						console.log('HotTable' + i, this.$refs['HotTable' + i])
						console.log('HotTable[0]' + i, this.$refs['HotTable' + i][0])
						this.$nextTick(() => {
							if (this.$refs['HotTable' + i] && this.$refs['HotTable' + i][0]) {

								console.log('HotTable[0] && ' + i, this.$refs['HotTable' + i][0])

								// this.$refs.HotTable.initHotTable();
								let arr_old = data[i].feeTypeArr;
								let arr_new = [];
								for (let j = 0, lnj = arr_old.length; j < lnj; j++) {
									let obj_old = arr_old[j];
									let obj = {
										lgsCode: obj_old.feePriceInfo.lgsCode,
										// Zones: obj_old.feePriceInfo.Zones,
										zone2Price: obj_old.feePriceInfo.zone2Price,
										zone3Price: obj_old.feePriceInfo.zone3Price,
										zone4Price: obj_old.feePriceInfo.zone4Price,
										zone5Price: obj_old.feePriceInfo.zone5Price,
										zone6Price: obj_old.feePriceInfo.zone6Price,
										zone7Price: obj_old.feePriceInfo.zone7Price,
										zone8Price: obj_old.feePriceInfo.zone8Price,
										zone9Price: obj_old.feePriceInfo.zone9Price,
										zone10Price: obj_old.feePriceInfo.zone10Price,
										zone11Price: obj_old.feePriceInfo.zone11Price,
										zone12Price: obj_old.feePriceInfo.zone12Price,
										feeType: obj_old.feeTypeInfo.feeType,
										isSupport: obj_old.feePriceInfo.isSupport,
										isAddSf: obj_old.feePriceInfo.isAddSf,
										billAtrr: "",
										remark: ""
									}
									arr_new.push(obj);
								}

								this.$refs['HotTable' + i][0].loadData(arr_new);

							}
						});
					}
				});
			},

			//请求分页数据
			getPageData() {
				if (!this.filterData.lgsKeyCode) {
					this.$message.warning("lgsKeyCode为空");
					return;
				}
				if (!this.filterData.channel) {
					this.$message.warning("channel为空");
					return;
				}
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhThirdLogiCarrierFeeTypePriceQuery, {
						"lgsCode": this.filterData.lgsKeyCode,
						"carrier": this.filterData.channel
						// offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						// limit: this.pagination.page_size, //当前页显示数目

						// userId: this.filterData.userId ? this.filterData.userId : null,
						// whNo: this.filterData.whNo ? this.filterData.whNo : null,
						// keyword: this.filterData.keyword ? this.filterData.keyword : null
					})
					.then(({
						data
					}) => {
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						this.$nextTick(() => {
							this.loadAllHotTableData(this.tableData);
						})

						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	@import url(@/assets/css/client_module.less);

	/deep/.el-input-number.is-without-controls .el-input__inner {
		padding: 0;
	}
</style>